// React
import React from 'react'
import ReactDOM from 'react-dom/client'

// Styles
import 'styles/animations.css'
import 'styles/variables.css'
import 'styles/globals.css'
import 'styles/fonts.css'

// Components
import App from './App'

// Etc.
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
)