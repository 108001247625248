import { Routes, Route } from 'react-router-dom';
// pages
import Home from './pages/Home';
import Create from './pages/Create';
import Update from './pages/Update';
import Posts from './pages/Posts';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import UserProfileTest from './pages/UserProfileTest';
import SignUpAdditional from './pages/SignUpAdditional';
import RealTimeChat from './pages/RealTimeChat';
import FeedOnly from './pages/FeedOnly'
import TermsOfService from './pages/TermsOfService';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/create' element={<Create />} />
      <Route path='/posts' element={<Posts />} />
      <Route path='/login' element={<Login />} />
      <Route path='/signUp' element={<SignUp />} />
      <Route path='/user' element={<UserProfileTest />} />
      <Route path='/additional' element={<SignUpAdditional />} />
      <Route path='/chat' element={<RealTimeChat />} />
      <Route path='/feed' element={<FeedOnly />} />
      <Route path='/id' element={<Update />} />
      <Route path='/TermsOfService' element={<TermsOfService />} />
    </Routes>
  );
}

export default App;
