import './primary-button.css';

// The button used for the home and create page
const PrimaryButton = ({
  text = 'text!',
  /* old animations
  animation = 'fadeIn 2s forwards',
  animationDelay = '1s',
  */
  animation = '',
  animationDelay = '',
  onClick = () => {},
  loggedCheck = false,
  loggedIn = true,
  top = '0%',
  left = '0%',
  width = '100px',
  height = '50px'
}) => {
  if(loggedCheck == true) {
    if (loggedIn == true) {
      text = 'Create';
    } else {
      text = 'Log in';
    }
  }
  return (
    <button
      onClick={onClick}
      style={{
        animation,
        animationFillMode: 'forwards',
        animationDelay,
        top,
        left,
        width,
        height,
      }}
      className='primary-button-button'>
      {text}
    </button>
    
  );
};

export default PrimaryButton;
