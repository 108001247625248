// Styles
import {useState } from 'react';

import './main-posts-card.css';

const MainPostsCard = ({
  chatLog,
  headerFontSize = 50,
  messageFontSize = 30,
  animation = '',
  marginLeft = '',
  marginRight = '',
  chatLogFromSelf = false,
  onClick = () => { },
}) => {
  const [text, setText] = useState('');
  const [borderColor, setBorderColor] = useState('');

  const [open, setOpen] = useState(false);

  // make a container that surrounds the other components and hold the state there its call context (store user data there and only need to fetch it once)
  return (
    <div className='main-posts'>
      <div
        className='click-change-post-size'
        data-local-open={open.toString()} // data- will be a custom attribute
        onClick={() => {setOpen(c => !c) }}>

        <h style={{ fontSize: headerFontSize }}>{chatLog.user}</h>
        <p style={{ fontSize: messageFontSize }}>{chatLog.message}</p>        
        <div>
        </div>
      </div>
    </div>
  );
};


export default MainPostsCard;
