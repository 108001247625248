import React from 'react';
import { useEffect, useState } from 'react';
import supabase from 'config/supabaseClient';
import ChatLogCard from 'components/ChatLogCard';
import InputBox from 'components/InputBox';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import PostsFixedHeader from 'components/PostsFixedHeader';
import { getUserID, getUsername } from 'userInformation';

// fetches the chat log, the chat log shows the user and the message

const RealTimeChat = () => {
  const [fetchError, setFetchError] = useState(null);
  const [chatLog, setchatLog] = useState(null);
  const [currentUserID, setCurrentUserID] = useState('');
  const [message, setMessage] = useState('Hello');
    
    const channelA = supabase
    .channel('schema-db-changes')
    .on(
      'postgres_changes',
      {
        event: 'INSERT', // '*' for all
        schema: 'public',
      },
      (payload) => console.log(payload)
    )
    .subscribe()

  
  return (
    <div className='page home'>
    <PostsFixedHeader
        paddingTop ={25}
        text='Logos'
        fontSize={80}
      />
    <form>
    {chatLog && (
    <div className='chatLog'>
      {chatLog.map((chatLog) => (
        <ChatLogCard key={chatLog.id} chatLog={chatLog}/>
      ))}
      </div>)}
    </form>
  </div>
  );
};
export default RealTimeChat;
