import { useState } from "react";
//import { InputText } from 'primereact/inputtext';

import './modern-input-box.css'

const ModernInput = ({
    label = 'woohoo',
    value = 'No Text!',
    type='text', // if type is Password then it will be hidden
    onChange = () => {},
    classNameInsert ='modern-input-float',
  }) => {
    return (
      // figure out how to change the color of the text in the input box, more over for the post fixed header you would want it grey
      <div class="modern-input-float-container">
        <input
          type={type}
          required
          onChange={onChange}
          className={classNameInsert}
        />
        <label>{label}</label>
        <span class="bar"></span>
      </div>
    );
  };
  
  export default ModernInput;