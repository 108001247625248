import './secondary-button.css';

// The button used for the home and create page
const SecondaryButton = ({
  text = 'No text!',
  animation = 'fadeIn 2s forwards',
  animationDelay = '1s',
  onClick = () => {},
  loggedCheck = false,
  loggedIn = true,
}) => {
  if(loggedCheck == true) {
    if (loggedIn == true) {
      text = 'Sign Out';
    } else {
      text = 'Go Home';
    }
  }
  
  return (
    <button
      onClick={onClick}
      style={{
        animation,
        animationFillMode: 'forwards',
        animationDelay,
      }}
      className='secondary-button-button'>
      {text}
    </button>
    
  );
};

export default SecondaryButton;
