import React from 'react';
import { useEffect, useState } from 'react';
import supabase from 'config/supabaseClient';
import ChatLogCard from 'components/ChatLogCard';
import InputBox from 'components/InputBox';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';

export const retrieveSelfUserID = async () => {
    const { data: { user:{id}}, error } = await supabase.auth.getUser(); // gets user data, more over the :{id} was added to get the id
    if(id) {
      //console.log('userID',id);
      return id; 
    }
    if (error) {
      console.log('error with ID');
    };
}
  
export const getUsername = async () => {
    const id = await retrieveSelfUserID();
    const { data:{0:{username}} , error } = await supabase.from('profiles').select('username').eq('id',id);
    //console.log('gamer',username);

    if(username == null){
      console.log('username is null');
    }
    return username;
}

export const getEmail = async () => {
  const { data: { user:{email} } } = await supabase.auth.getUser()
  //console.log(email);
  return email;
}

export const signOutAccount = async () => {
    const { error } = await supabase.auth.signOut();
    if(error) {
      //console.log('the account has not been signed out');
    }
    else {
      //console.log('signed out');
    }
}

export const insertUserName = async (newUserName) => {
  const {data} = await supabase.from('profiles').insert({username:newUserName})
  //console.log(data);
}

export const updateUserName = async (newUserName) => {
  let isItNull = false;
  const userID = await retrieveSelfUserID();
  //console.log(userID);
  const {data} = await supabase.from('profiles').update({username:newUserName}).eq('id',userID).select();
  console.log('data',data);
  if(data==null){
    isItNull = true;
  }
  return isItNull;
}

export const getSessionDetails = async () => {
    const { data, error } = await supabase.auth.getSession();
    //console.log(data);
    //console.log(error);
}

export const signInWithPass = async () => {
    const { data, error } = await supabase.auth.signInWithPassword({
      email: 'vadirilo.uqujegiv@rungel.net',
      password: 'asdfasdf',
    });

    if(error) {
      //console.log('error with signing in with password', error);
    }
    if(data) {
      //console.log('the sign in data is', data);
    }
}
export const updateName = async (newName) => {
    const userID = await retrieveSelfUserID();
    const {data} = await supabase.from('profiles').update({username:newName}).eq('full_name',userID)
    //console.log(data);  
}

export const getUserID = async (username) => {
  const { data:{0:{id}}, error } = await supabase.from('profiles').select('id').eq('username',username);
  console.log('the UserID for',username,'is',id);
  return id;
}