import './hover-link.css';

// currently ligning up the input boxes
// The button used for the home and create page
// <h>{value}</h>//
const HoverLink = ({
  fontSize = 30,
  label = 'Animation',
  hreference='#'
}) => {
  if(window.outerWidth < 410) {
    fontSize = 20;
  }
  return (
    <div className='HoverLinkContainer'>
      <a style={{
        fontSize,
      }}href={hreference}>{label} </a>
    </div>
  );
};

export default HoverLink;