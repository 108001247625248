import React from 'react';
import { useEffect, useState } from 'react';
import supabase from 'config/supabaseClient';
import MainPostsCard from 'components/MainPostsCard';
import InputBox from 'components/InputBox';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { getUsername, signOutAccount, retrieveSelfUserID} from 'userInformation';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import PostsFixedHeader from 'components/PostsFixedHeader';

// fetches the chat log, the chat log shows the user and the message

const Posts = () => {
  const [user, setUser] = useState('');
  const [fetchError, setFetchError] = useState(null);
  const [chatLog, setchatLog] = useState(null);
  const [message, setMessage] = useState('');
  const status = true;
  const navigate = useNavigate();
  const [loggedUser, setLoggedUser] = '';

  const checkForChanges = async() => {
    supabase.channel('any').on('postgres_changes', { event: '*', schema: 'public', table: 'messageBoard' }, payload => {
      //console.log(payload);
    }).subscribe();
  }

  const gaming = async() => {
    const selfUsername  = await getUsername();
    //console.log(selfUsername);
    setUser(selfUsername);
    return selfUsername;
  }
    // need to find a way to retrieve the username from the profiles table if the tokens are the same
  useEffect(() => {
    const fetchChatLog = async () => {
      //console.log(await selfPosts());
      const { data, error } = await supabase.from('messageBoard').select();

      if (error) {
        setFetchError('Could not fetch the messages');
        //console.log(error);
      }
      if (data) {
        //console.log('data funny', data);
        setchatLog(data);
      }
    };
    fetchChatLog();
    checkForChanges();
  }, []);

const handleSubmit = async (event) => {
  event.preventDefault();
  const username = await getUsername();
  const { data, error } = await supabase.from('messageBoard').insert([{user:username, message}]);
  console.log(data);
}
gaming();
return (
  <div className='page home'>
    <PostsFixedHeader
        paddingTop ={25}
        text='Logos'
        fontSize={80}
      />
    <form onSubmit={handleSubmit}>
    {chatLog && (
    <div className='chatLog'>
      {chatLog.map((chatLog) => (
        //<ChatLogCard key={chatLog.id} chatLog={chatLog} loggedUser={user}/>
        <MainPostsCard key={chatLog.id} chatLog={chatLog} loggedUser={user}/>
      ))}
      </div>)}
    </form>
    <PrimaryButton text='Sign Out' onClick={() => {signOutAccount() && navigate('/')}} loggedIn={status}/>
    <SecondaryButton text='Create Post' onClick={() => {navigate('/create')}} loggedIn={status}/>
    <SecondaryButton text='User Profile' onClick={() => {navigate('/user')}} loggedIn={status}/>
  </div>
);
};

export default Posts;
