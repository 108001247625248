// Styles
import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { getUserID, getUsername } from 'userInformation';
import { useNavigate } from 'react-router-dom';

import './chat-log-card.css';
import InputBox from '../InputBox';

const ChatLogCard = ({
  chatLog,
  headerFontSize = 50,
  messageFontSize = 30,
  animation = '',
  marginLeft = '',
  marginRight = '',
  chatLogFromSelf = false,
  onClick = () => { },
}) => {
  const [text, setText] = useState('');
  const [borderColor, setBorderColor] = useState('');

  const [inputText, setInputText] = useState('input funny text');
  const [open, setOpen] = useState(false);

  /*
  if the chat log is sent from the user, then the message swipes from the left,
  if not, then the message swipes in from the right
  */
  if (chatLogFromSelf) {
    animation = 'slideInFromLeft 1s forwards, fadeIn 3s';
    text = 'black';
    //marginLeft = '0%';
  } else {
    animation = 'slideInFromRight 1s forwards, fadeIn 3s';
    //marginRight = '50%';
  }

  async function username() {
    const selfUsername = await getUsername();
    return selfUsername;
  }

  async function chatLogInformation() {
    console.log('id', chatLog, 'username', chatLog.user, 'message', chatLog.message);
    if (chatLog.user === await username()) {
      console.log('hey thats me');
      setText('#555');
      setBorderColor('green');
      return text;
    }
  }

  // make a container that surrounds the other components and hold the state there its call context (store user data there and only need to fetch it once)
  return (
    <div className='chat-log-card-location'>
      <div
        className='chat-log-card'
        data-local-open={open.toString()} // data- will be a custom attribute
        onClick={() => { chatLogInformation() && setOpen(c => !c) }}>

        <h style={{ fontSize: headerFontSize }}>{chatLog.user}</h>
        <p style={{ fontSize: messageFontSize }}>{chatLog.message}</p>
  {/*<InputBox
          label=''
          required
          value={inputText}
          onChange={(event) => setInputText(event.target.value)}
        />  */}
        
        <div>
        </div>
      </div>
    </div>
  );
};

//        }} onClick={() => {chatLogInformation() && navigate('/')}}>

export default ChatLogCard;
