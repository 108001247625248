import './posts-fixed-header.css';
import InputBox from '../InputBox';
import PrimaryButton from '../Buttons/PrimaryButton';
import HoverLink from '../HoverLink';
import { useState, Fragment } from 'react';

const PostsFixedHeader = ({
  
  text = 'Logos',
  color = '#ffffff',
  animation,
  fontSize = 60,
  paddingTop = 0,
  paddingBottom = 0,
  animationDelay = '0s',
}) => {
  const [inputText, setInputText] = useState('input');
  return (
    //now we have a typing box working, need to figure out, type in username, return posts with that exact or similar username
    <div className='stickyHeader'>
    <p
      style={{
        fontSize,
        animation,
        color,
        animationFillMode: 'forwards',
        paddingTop,
        paddingBottom,
        animationDelay,
      }}
      className=''> {text} 
    </p>
    {/*  <InputBox
          label='Search User (not working yet) '
          required
          value={inputText}
          onChange={(event) => setInputText(event.target.value)}
        />
        search box concept but no need to use it right now */}
   
    </div>
  );
};
// check to see if you can fix line 21 and get rid of className=''
export default PostsFixedHeader;
