// Styles
import supabase from 'config/supabaseClient';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import HoverLink from 'components/HoverLink';
import WelcomeHeader from 'components/WelcomeHeader';
import {retrieveSelfUserID } from 'userInformation';
import PostsFixedHeader from 'components/PostsFixedHeader';
import { useState, useEffect } from 'react';
import ModernInputBox from 'components/ModernInputBox';

const Home = () => {
  const getID = async () => {
    const id = await retrieveSelfUserID();
  }
  getID(); // need to fix this so it only needs to be created once

  const [email, setEmail] = useState('Email');
  const [displayName, setDisplayName] = useState('Username');
  const [password, setPassword] = useState('Password');

  const navigate = useNavigate();

  async function signOut() {
    const { error } = await supabase.auth.signOut()
  }
  
  const handleSubmit = async (submitEvent) => { // on submit login
    try{
      signOut();
      submitEvent.preventDefault();
      const { data, error } = await supabase.auth.signInWithPassword({
        email: email,
        password: password,
      })
      console.log("sign in sucessful");
      navigate('/feed');
    }
    catch (error) {
      console.log("LMAO")
      alert(error);
    }
  };

    return (
      <div className='mainDiv'>
        <div className='header'>   
          <div className='fixedHeaderDivider'>        
            <PostsFixedHeader/>
          </div>
        </div>
        <div className='body'>
          <div className='divInputBox'>
            <div className='titleText'>          
              <WelcomeHeader text='Login'/>
            </div>
            <ModernInputBox label='Username'/>
            <ModernInputBox label='Password' type='Password'/>
            <PrimaryButton text='Create' onClick={handleSubmit}/>  
            <div className='questionToSignUp'>
              <div>Dont have an Account?</div>
              <HoverLink label='SignUp' hreference='/SignUp'/>
            </div>
          </div>
        </div>
          <div className='buttonBox'></div>
      </div>
    );
  };

export default Home;