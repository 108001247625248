import './input-box.css';

// currently ligning up the input boxes
// The button used for the home and create page
// <h>{value}</h>//
const InputBox = ({
  label = 'woohoo',
  value = 'No Text!',
  type='text', // if want to do passwords
  onChange = () => {},
  classNameInsert ='round-hover-input',
  animation='fadeIn 3s forwards, slideInFromLeft 1s forwards',
}) => {
  return (
    // figure out how to change the color of the text in the input box, more over for the post fixed header you would want it grey
    <div className='round-hover-input-box-container'>
      <input
        type={type}
        required
        onChange={onChange}
        className={classNameInsert} 
      />
    </div>
  );
};

export default InputBox;