import supabase from 'config/supabaseClient';
import {useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PrimaryButton from 'components/Buttons/PrimaryButton';
import SecondaryButton from 'components/Buttons/SecondaryButton';

import InputBox from 'components/InputBox';
import WelcomeHeader from 'components/WelcomeHeader';
import { retrieveSelfUserID, signOutAccount, getUserdata, updateName, updateUserName, getUsername, getEmail } from 'userInformation';

// test page for user profile login
// able to update username and name, need to figure out how to add update timestamp
const UserProfileTest = () => {
  const [user, setUser] = useState('');
  const [email, setEmail] = useState('');

  const navigate = useNavigate();

  async function userInformation() {
    const selfUsername  = await getUsername();
    const selfEmail = await getEmail();
    setUser(selfUsername);
    setEmail(selfEmail);
  }

  userInformation();

  const handleSubmit = async (submitEvent) => {
    submitEvent.preventDefault();
    signOutAccount();
    navigate('/');
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <WelcomeHeader
          text='User Profile'
          fontSize={80}
          animation='fadeIn 3s forwards, slideInFromLeft 1s forwards'
        />
        <WelcomeHeader
          text={'username: ' + user}
          fontSize={40}
          animation='fadeIn 3s forwards, slideInFromLeft 1s forwards'
          paddingTop={5}
          paddingBottom={5}
        />
        <WelcomeHeader
          text={'email: ' + email}
          fontSize={40}
          animation='fadeIn 3s forwards, slideInFromLeft 1s forwards'
          paddingTop={5}
          paddingBottom={5}
        />
      </form>
      <PrimaryButton text='Sign Out' onClick={()=>{signOutAccount() && navigate('/')}} />
      <SecondaryButton  text='Go Feed' onClick={()=>{navigate('/posts')}} />
    </div>
  );
};

export default UserProfileTest;
