import supabase from 'config/supabaseClient';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PrimaryButton from 'components/Buttons/PrimaryButton';
import WelcomeHeader from 'components/WelcomeHeader';
import InputBox from 'components/InputBox';
import PostsFixedHeader from 'components/PostsFixedHeader';
//import {updateUserName} from '..../userInformation'

// added confirm password and wont allow user to proceed if passwords are different and/or if the the email is not valid
// only need email and password for this page, then verify, then add username and other profile related things


const SignUpAdditional = () => {

  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [name, setName] = useState('');

  const navigate = useNavigate();

  const getUserID = async () => {
    const { data: { user:{id}} } = await supabase.auth.getUser(); // gets user data, more over the :{id} was added to get the id
    console.log(id);
    return id;
  }

  const getSessionDetails = async () => {
    const { data, error } = await supabase.auth.getSession();
    console.log(data);
    console.log(error);
  }

  const retrieveSelfUserID = async () => {
    const { data: { user:{id}}, error } = await supabase.auth.getUser(); // gets user data, more over the :{id} was added to get the id
    if(id) {
      //console.log('userID',id);
      return id; 
    }
    if (error) {
      //console.log('error with ID');
    };
}

  const updateUserName = async (newUserName) => {
    let isItNull = false;
    const userID = await retrieveSelfUserID();
    //console.log(userID);
    const {data} = await supabase.from('profiles').update({username:newUserName}).eq('id',userID).select();
    //console.log('data',data);
    if(data===null){
      isItNull = true;
    }
    return isItNull;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    getSessionDetails();

    if(await updateUserName(username) === true) {
      alert('username is invalid');
    }
    else {
      navigate('/create');
    }
  }
    return (
    <div>
      <PostsFixedHeader
        paddingTop ={25}
        text='Logos'
        fontSize={80}
      />
      <form onSubmit={handleSubmit}>
        <WelcomeHeader
          text=' Create a Username'
          fontSize={80}
          animation='fadeIn 3s forwards, slideInFromLeft 1s forwards'
          paddingTop={'13%'}
        />
        <InputBox
          label='Enter Username'
          required
          value={username}
          onChange={(event) => setUsername(event.target.value)}
        />
        <div className='buttonSpawn'>
          <PrimaryButton text='Go Login'/>
        </div>
      </form>
    </div>
  );
};

export default SignUpAdditional;