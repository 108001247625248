import supabase from 'config/supabaseClient';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import WelcomeHeader from 'components/WelcomeHeader';
import InputBox from 'components/InputBox';
import {retrieveSelfUserID, getUsername} from 'userInformation';
import PostsFixedHeader from 'components/PostsFixedHeader';


// need to get rid of sign in feature, maybe even need to get rid of this page
// may need to get rid of the submit button, lets see
const Create = () => {
  retrieveSelfUserID();
  const [user, setUser] = useState('*ERROR NO ONE LOGGED*');
  const [message, setMessage] = useState('');

  const run = async()  => {
    const username = await getUsername();
    console.log(username);
    setUser(username); 
    return username;
  }

  const navigate = useNavigate();

  const handleSubmit = async (submitEvent) => {
    submitEvent.preventDefault(); 
    const { data, error } = await supabase.from('messageBoard').insert([{user: user, message}]);
    navigate('/posts');
  }
  run();
  return ( 
    <div>
      <PostsFixedHeader
        paddingTop ={25}
        text='Logos'
        fontSize={80}
      />
      <form onSubmit={handleSubmit}>
        <WelcomeHeader
          text={'Welcome ' + user}
          fontSize={80}
          animation='fadeIn 3s forwards, slideInFromLeft 1s forwards'
        />
        <InputBox
          label='Input Message'
          required
          value={message}
          onChange={(event) => setMessage(event.target.value)}
        />
        {/* need to figure out to click on button then create a a post, also give option to edit profile too */}
        <div className='buttonSpawn'>
          <PrimaryButton text='Make Post' />
          <PrimaryButton text='View Posts' onClick={() => {navigate('/posts');}} />

        </div>
      </form>
    </div>
  );
};

export default Create;
