// Styles
import supabase from 'config/supabaseClient';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import HoverLink from 'components/HoverLink';
import WelcomeHeader from 'components/WelcomeHeader';
import {retrieveSelfUserID } from 'userInformation';
import PostsFixedHeader from 'components/PostsFixedHeader';
import { useState, useEffect } from 'react';
import ModernInputBox from 'components/ModernInputBox';

const Home = () => {
  const getID = async () => {
    const id = await retrieveSelfUserID();
  }
  getID();

  const [email, setEmail] = useState('Email');
  const [displayName, setDisplayName] = useState('Username');
  const [password, setPassword] = useState('Password');

  const navigate = useNavigate();

  const handleSubmit = async (submitEvent) => { // on submit login
    try{
      submitEvent.preventDefault();
      const { data, error } = await supabase.auth.signUp({
        email: email,
        password: password,
        options: {
          data: {
            displayName: displayName,
          }
        }
      })
    }
    catch (error) {
      alert(error);
    }
  };

    return (
      <div className='mainDiv'>
      <body className='no-scroll'>
        <div className='header'>   
          <div className='fixedHeaderDivider'>        
            <PostsFixedHeader/>
          </div>
        </div>
        <div className='body'>
          <div className='divInputBox'>
            <div className='titleText'>          
              <WelcomeHeader text='SignUp'/>
            </div>
            <ModernInputBox label='Email' value={email} onChange={(e)=>setEmail(e.target.value)}/>
            <ModernInputBox label='Username' value={displayName} onChange={(e)=>setDisplayName(e.target.value)}/>
            <ModernInputBox label='Password' value={password} onChange={(e)=>setPassword(e.target.value)} type='Password'/>
            <ModernInputBox label='Confirm Password' type='Password'/>
            <PrimaryButton text='Create' onClick={handleSubmit}/>  
            {/* need to change button size */}
            <div className='questionToSignUp'>
              <div>Have an account?</div>
              <HoverLink label='Login' hreference='/Login'/>
            </div>
          </div>
        </div>
          <div className='buttonBox'></div>
        </body>
      </div>
    );
  };

export default Home;