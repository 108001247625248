// Styles
import './home.css';
import supabase from 'config/supabaseClient';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import HoverLink from 'components/HoverLink';
import WelcomeHeader from 'components/WelcomeHeader';
import { signOutAccount, retrieveSelfUserID } from 'userInformation';
import PostsFixedHeader from 'components/PostsFixedHeader';
//import { render } from '@testing-library/react';
import { useState, useEffect } from 'react';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import ModernInputBox from 'components/ModernInputBox';

const Home = () => {
  const getID = async () => {
    const id = await retrieveSelfUserID();
    //console.log(id);
  }
  getID();
  //signOutAccount();

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  let left = '10%';

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const imageSize = windowSize.width > 1000 ? '90%' : '100%'; 
  // If the window width is greater than 100 pixels, the imageSize is set to '100%', otherwise it is set to '300px'.
  // Where great ideas may thrive, flourish, prosper
  // <img className='gathering' src='/images/gathering.jpg' alt='' style={{ width: '60%', height: 'auto' }}/>

  const [user, setUser] = useState('Email');
  const [password, setPassword] = useState('Password');

  const navigate = useNavigate();

  const handleSubmit = async (submitEvent) => {
    submitEvent.preventDefault();

    const { data, error } = await supabase.auth.signInWithPassword({
      email: user,
      password: password,
    });
    navigate('/create');
  };

    return (
      <div className='mainDiv'>
        <div className='header'>   
          <div className='fixedHeaderDivider'>        
            <PostsFixedHeader/>
          </div>
        </div>
        <div className='body'>
          <div className='resizeFontOnDisplay'>
            <div className='bigBox'>          
            <WelcomeHeader text='Where great ideas thrive!'/>
            <div className='divInputBox'>
              <div className='titleText'>          
                {/* <WelcomeHeader text='Login'/> */}
              </div>
              <ModernInputBox label='Username'/>
              <ModernInputBox label='Password' type='Password'/>
              <PrimaryButton
            text='Login'
            animationDelay='0s'
            onClick={() => {
              navigate('/create');
            }}
          />
              {/* need a login button */}
              <div className='questionToSignUp'>
                <div className='resizeFontOnDisplay'>Dont have an Account?</div>
                <HoverLink label='SignUp' hreference='/SignUp'/>
              </div>
            </div>
          </div></div>
        </div>
      </div>
    );
  };

export default Home;

/*
<PrimaryButton
            text='Join'
            animation='fadeIn 0ms'
            animationDelay='0s'
            onClick={() => {
              navigate('/TermsOfService');
            }}
            left='0%'
            top='75px'
            width='180px'
          /> */


/*
<div className='linksDivider'> 
            <HoverLink label='SignUp' hreference='/SignUp'/>
            <HoverLink label='Login' hreference='/Login'/>
            <HoverLink label='Posts'/>
            <HoverLink label='About'/>
          </div>
          */