import './welcome-header.css';

const WelcomeHeader = ({
  text = 'No text!',
  animation = '',
  // old animation animation = 'fadeIn 3s forwards, slideInFromLeft 1s forwards',
  fontSize = 50,
  paddingTop = 20,
  paddingBottom = 0,
  animationDelay = '1s', // used for old animation
  top,
  left,
}) => {
  if(window.outerWidth < 410) {
    fontSize = 30;
  }
  return (
    <div>
      <p
      style={{
        fontSize,
        animation,
        animationFillMode: 'forwards',
        paddingTop,
        paddingBottom,
        animationDelay,
        top,
        left,
      }}
      className='welcome-titlePopUp'>
      {text}
    </p></div>

  );
};

export default WelcomeHeader;
