import supabase from 'config/supabaseClient';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PrimaryButton from 'components/Buttons/PrimaryButton';
import WelcomeHeader from 'components/WelcomeHeader';
import InputBox from 'components/InputBox';
import { insertUserName } from 'userInformation';
import PostsFixedHeader from 'components/PostsFixedHeader';

// added confirm password and wont allow user to proceed if passwords are different and/or if the the email is not valid
// only need email and password for this page, then verify, then add username and other profile related things
const TermsOfService = () => {
  const navigate = useNavigate();
  //<PostsFixedHeader paddingTop ={25} text='Logos' fontSize={80} />
  return (
    <div>
      <form>
        <WelcomeHeader
          text='Terms of Service'
          fontSize={80}
          animation='fadeIn 3s forwards, slideInFromLeft 1s forwards'
          paddingTop={'13%'}
        />
        <WelcomeHeader
          text='By continuing you consent to the collection of your data, limited to but not including your email, username, password, 
          and posts for Logos to function.'
          fontSize={30}
          animation='fadeIn 5s forwards'
          paddingTop={25}
        />

      </form>
      <PrimaryButton text='Go Home' onClick={() => {navigate('/')}} />
      <PrimaryButton text='I agree' onClick={() => {navigate('/signUp')}} />
    </div>
  );
};

export default TermsOfService;