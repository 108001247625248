import React from 'react';
import { useEffect, useState } from 'react';
import supabase from 'config/supabaseClient';
import ChatLogCard from 'components/ChatLogCard';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { getUserID, getUsername, signOutAccount} from 'userInformation';
import PostsFixedHeader from 'components/PostsFixedHeader';
import InputBox from 'components/InputBox';

// fetches the chat log, the chat log shows the user and the message

const FeedOnly = () => {
  const [fetchError, setFetchError] = useState(null);
  const [chatLog, setchatLog] = useState(null);
  const [user, setUser] = useState('null');
  const [message, setMessage] = useState('');
  const status = true;
  const navigate = useNavigate();

  console.log(getUserID);
  const changeDetected = async() => {
    supabase.channel('any').on('postgres_changes', { event: '*', schema: 'public', table: 'messageBoard' }, payload => {
      console.log(payload);
      // console.log("CHANGE DETECTED");
    }).subscribe();
  }

  const makePost = async() => {
    const { data, error } = await supabase.from('messageBoard').insert([{user, message}]);
  }

  const fetchChatLog = async () => {
    const { data, error } = await supabase.from('messageBoard').select();
    
    if (error) {
      setFetchError('Could not fetch the messages');
    }
    if (data) {
      setchatLog(data);
    }
  };
  fetchChatLog();

  const selected = () => {
    //console.log('card has been selected');
    return true;
  }

  const handleSubmit = (e) => {
    e.preventDefault(); // prevents page from refreshing
    const post = {user, message};
    console.log(post);
  }

  return (
    <div className='page home'>
      <PostsFixedHeader
        paddingTop ={25}
        text='Logos'
        fontSize={80}
      />
      {chatLog && (
        <div className='chatLog'>
        {chatLog.map((chatLog) => (
          <ChatLogCard onClick={()=>{selected()}}
          key={chatLog.id} chatLog={chatLog}/>
        ))}
        </div>)}

      
      <InputBox onChange={(e) => setMessage(e.target.value)}/>
      <PrimaryButton text='Post' onClick={() => {makePost()}}/>
      <PrimaryButton text='Go Home' onClick={() => {signOutAccount() && navigate('/')}} loggedIn={status}/>
      </div>        
  );
};

export default FeedOnly;
